import {
    Box,
    Clone,
    MapControls,
    useGLTF,
} from "@react-three/drei";
import { useRef } from "react";
import * as THREE from "three";
import { Physics, RigidBody} from "@react-three/rapier";

import Title from "./title";

export default function PhysicsDemo() {
    const capsule = useGLTF("./techPharmCapsule.glb");
    // const { camera,size, viewport } = useThree();
    const floorRef = useRef<THREE.Mesh | null>(null)
    // camera.lookAt(0, 0, 0);
    const groupRef = useRef<THREE.Group | null>(null);

    // const limitPanningDistance = useCallback(
    //     (e?: THREE.Event) => {
    //       const {width: w, height: h} = viewport
    
    //       const x = e?.target.target.x;
    //       const y = e?.target.target.y;
    //       if (x < -w || x > w) {
    //         e?.target.target.setX(x < -w ? -w : w);
    //         camera.position.setX(x < -w ? -w : w);
    //       }
    //     //   if (y < minY || y > maxY) {
    //     //     e?.target.target.setY(y < minY ? minY : maxY);
    //     //     camera.position.setY(y < minY ? minY : maxY);
    //     //   }
    //     },
    //     [camera.zoom, size]
    //   );

    return (
        <group ref={groupRef} position={[0, 0, -5]}>
            <MapControls 
                    maxZoom={0} 
                    minZoom={-10} 
                    enableRotate={false} 
                    />
            <ambientLight intensity={0.25} />
            <directionalLight
                color="white"
                target={capsule.scene}
                position={[10, 10, 50]}
                intensity={1}
            />
            <Title position={[-1, -1, 0]} rotation={[Math.PI / 2, Math.PI, Math.PI]} />
            <Physics gravity={[0, -10, 0]}>
                <RigidBody colliders="cuboid" restitution={0.5} type="fixed">
                    <Box
                        ref={floorRef}
                        position={[0, -2, 0]}
                        rotation={[Math.PI / 2, 0, 0]}
                        scale={[200, 200, 1]}
                   >
                    <meshBasicMaterial color={"#fff"}  transparent/>
                   </Box>
                </RigidBody>

                {Array.from(Array(30).keys()).map((a) => (
                    <RigidBody
                        key={a}
                        position={[
                            Math.random() * 10 - 4,
                            5,
                            Math.random() * 10 + 4,
                        ]}
                        rotation={[
                            Math.random() * Math.PI,
                            Math.random() * Math.PI,
                            Math.random() * Math.PI,
                        ]}
                        >
                        <Clone object={capsule.scene} scale={0.25}/>
                    </RigidBody>
                ))}
            </Physics>
        </group>
    );
}

