import {
    animated,
    useSprings,
} from '@react-spring/web';

export default function Intro({ ...props }) {
    const myName = "Ryan_Brophy"
    const [titleSprings, api]= useSprings(
        myName.length,
        () => ({
            from: {opacity: 0, y: -100},
            to: {opacity: 1, y: 0},
            delay: 200 + (Math.random()*500),
            config: {
                tension: 500,
                friction: 12,
            }
        }),
        [])

    return (
            <div className="flex justify-center sm:justify-start">
                {titleSprings.map((props, index) =>
                    <animated.div className="text-4xl sm:text-6xl text-white text-center font-extrabold px-0.5" style={props} key={index}>
                        {(myName[index] === '_') ? <span className="pr-2" /> : myName[index]}
                    </animated.div>
                )}
            </div>
    );
}

