type TAboutProps = {
    handleClick: (num: number | null) => void
}

export default function Leadership({handleClick}: TAboutProps) {
    return (
        <div>
            <h1 className="text-2xl font-bold">Leadership</h1>
            <h2 onClick={() => handleClick(null)}>Back</h2>
        </div>
    );
}

