import { Box, Image, Text, useScroll } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { useRef } from "react";
import * as THREE from "three";

function Title({ ...props }) {
    // const scroll = useScroll();
    const { width, height } = useThree((state) => state.viewport);
    const groupRef = useRef<THREE.Group | null>(null);
    useFrame((state, delta) => {
        // groupRef.current!.children[0].rotation.x = scroll.range(0, 1) * -width;
        // groupRef.current!.children[1].rotation.x = scroll.range(0, 1) * -width;
        // state.camera.position.x = scroll.range(0, 1/5) * 5
        // state.camera.rotation.y = -(scroll.range(0, 1) * Math.PI * 2);
        // groupRef.current!.rotation.z = Math.sin(state.clock.elapsedTime)
        // state.camera.setRotationFromEuler(groupRef.current!.rotation)
    });

    return (
        <group ref={groupRef} {...props}>
            <Text
                textAlign="left"
                anchorX="left"
                position={props.position}
                color="black"
                >
                Ryan
            </Text>
            <Text
                textAlign={"left"}
                color="black"
                anchorX="left"
                position={[
                    props.position[0],
                    props.position[1] - 1,
                    props.position[2],
                ]}>
                Brophy
            </Text>
        </group>
    );
}

export default Title;
