import * as React from "react";
import { Canvas } from "@react-three/fiber";
import {PerspectiveCamera} from "@react-three/drei";
import PhysicsDemo from "../../components/threejs/physicsDemo";


export default function CapsulePhysics() {
    const canvasRef= React.useRef<HTMLCanvasElement | null>(null)

    return (
        <Canvas
            ref={canvasRef}
            camera={{manual: true}}
            style={{
                height: "100vh",
                width: "100vw",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                overflow: 'scroll'
            }}>
             <PerspectiveCamera makeDefault position={[0,10,0]} />

             <PhysicsDemo />
        </Canvas>

    )

}
