import {Link} from "react-router-dom";
import {animated, useChain, useSpring, useSpringRef, useTransition, useScroll} from '@react-spring/web';
import {Parallax, ParallaxLayer} from '@react-spring/parallax'
import Intro from "../components/resume/Intro";
import PanelWrapper from "../components/resume/PanelWrapper";
import {useRef, useState} from "react";
import Technology from "../components/resume/Technology";
import Pharmacy from "../components/resume/Pharmacy";
import Leadership from "../components/resume/Leadership";
import Profile from "../components/resume/Profile";

export default function Home({ ...props }) {
    const [focused, setFocus] = useState<number|null>(-1)
    const containerRef = useRef<HTMLDivElement>(null!)

    const transRef = useSpringRef()
    const transitions= useTransition(
        "Ryan Brophy".split(''),
       ({
           ref: transRef,
           from: {opacity: 0, y: -50},
           enter: {opacity: 1, y: 0},
           leave: {opacity: 0},
           trail: 100
       }))

    const handleFocus = (num: number | null): void => {
        if (focused === num) {
            setFocus(null)
            return
        }
        setFocus(num)
        return
    }

    const isFocused = (num: number) => focused === num;


    return (
        <div className="bg-gradient-to-b from-primary to-primaryLight w-screen h-screen absolute top-0 left-0 overflow-hidden" ref={containerRef}>
            <div className="pt-12 px-12">
                <Intro />
                <Profile />
            </div>
            <PanelWrapper offset={1} handleClick={handleFocus} focused={focused}>
                <Technology handleClick={handleFocus}/>
            </PanelWrapper>
            <PanelWrapper offset={2} handleClick={handleFocus} focused={focused}>
                <Pharmacy handleClick={handleFocus}/>
            </PanelWrapper>
            <PanelWrapper offset={3} handleClick={handleFocus} focused={focused}>
                <Leadership handleClick={handleFocus}/>
            </PanelWrapper>
        </div>
    );
}

