import * as React from "react";
import { Canvas } from "@react-three/fiber";
import {
    ScrollControls,
} from "@react-three/drei";
import UseScrollDemo from '../../components/threejs/useScrollDemo'


export default function ThreeScroll() {
    const canvasRef= React.useRef<HTMLCanvasElement | null>(null)

    return (
        <Canvas
            ref={canvasRef}
            camera={{manual: true}}
            style={{
                height: "100vh",
                width: "100vw",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                overflow: 'scroll'
            }}>
            {/* <PerspectiveCamera makeDefault position={[0,10,0]} /> */}

            {/* <Carousel /> */}
            <ScrollControls pages={5}>
                <UseScrollDemo />
            </ScrollControls>
        </Canvas>

    )

}
