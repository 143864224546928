import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "../pages/home";
import MainLayout from "../layouts/MainLayout";
import ThreeScroll from "../pages/r3f/threeScroll";
import CapsulePhysics from "../pages/r3f/capsulePhysics";

const router = createBrowserRouter([
    {
        path: "/",
        element: <MainLayout />,
        children: [
            {
                path: "",
                element: <Home />,
            },
            {
                path: "3d-scroll",
                element: <ThreeScroll />,
            },
            {
                path: "capsule-physics",
                element: <CapsulePhysics />,
            },
        ],
    },
]);

function Router() {
    return (
        <RouterProvider router={router} />
    );
}

export default Router;
