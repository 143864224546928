import {useSpring, animated} from "@react-spring/web";

export default function Profile () {
    const [spring, api] = useSpring(() => ({
        from: {
            opacity: 0
        },
        to: {
            opacity: 1
        },
        delay: 1000
    }))
    return (
        <animated.div className="flex flex-col sm:flex-row p-4 sm:p-12 w-full items-center sm:align-center sm:justify-center sm:space-x-12" style={spring}>
            <img src="/IMG_4317.jpg" alt="Picture of Ryan Brophy" className="rounded-3xl sm:w-72 sm:h-72 w-36 h-36 shadow-xl  drop-shadow-2xl"/>
            <div className="text-center text-white">
                <p className="sm:text-2xl pt-5">A software engineer with a background in pharmacy.</p>
            </div>
        </animated.div>
    )
}