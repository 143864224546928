import React, {PropsWithChildren, useEffect, useMemo} from "react";
import {useSpring, animated} from "@react-spring/web";

type TPanelWrapperProps = {
    offset: number,
    focused: number | null,
    handleClick: (num: number) => void
}

export default function PanelWrapper({ children, offset, handleClick, focused }: PropsWithChildren<TPanelWrapperProps>) {
    const height = window.innerHeight
    const width = window.innerWidth
    const isMobile = useMemo(() => width < 600, [])
    const topGapRatio = !isMobile ? 1.5 : 1.9
    const xGapRatio = !isMobile ? 140 : 20
    const yGapRatio = width > 600 ? 40 : 80
    const multiplier = offset - 1

    const [styles, api] = useSpring(() => ({
        from: {
            top: height,
            left: 40 + (multiplier * xGapRatio),
            scale: 1,

        },
    }))

    useEffect(() => {
        if (focused === -1) {
            api.start({
                to: {
                    top: height / topGapRatio + (multiplier * yGapRatio),
                    left: (isMobile ? 20 : 40) + (multiplier * xGapRatio),
                },
                delay: 800
            })
            return
        } else if (!focused) {
            api.start({
                to: {
                    top: height / topGapRatio + (multiplier * yGapRatio),
                    left: (isMobile ? 20 : 40) + (multiplier * xGapRatio),
                },
            })
            return
        } else if (focused === offset) {
            api.start({
                to: {
                    top: !isMobile ? 150 : 110,
                    left: !isMobile ? 40 : 20,
                }}
            )
            return
        } else if (focused && focused !== offset) {
            api.start({
                to: {
                    top: height,
                    left: 40 + (multiplier * xGapRatio),
                }
            })
            return
        }
    }, [focused])


    const grow = () => {
        api.start({to: { scale: 1.03  }})
    }

    const shrink = () => {
        api.start({to: { scale: 1  }})
    }
    return (
        <animated.div
            style={styles}
            onMouseEnter={() => grow()}
            onMouseLeave={() => shrink()}
            onClick={() => handleClick(offset)}
            className={`absolute bg-gradient-to-b from-primary to-primaryLight w-screen h-screen text-white p-4 z-12 rounded-3xl hover:border-white hover:border-2`}>
            {children}

        </animated.div>
    );
}

