import {
    Scroll,
    useAnimations,
    useGLTF,
    useScroll,
} from "@react-three/drei";
import {  useFrame, useThree} from "@react-three/fiber";
import { Suspense, useCallback, useEffect, useRef } from "react";
import * as THREE from "three";
import Loader from "../../pages/r3f/loading";

export default function UseScrollDemo() {
    const scroll = useScroll();
    const {set, scene, camera,  viewport } = useThree()
    const camRef = useRef<THREE.PerspectiveCamera | null>()
    const targetObject1 = new THREE.Object3D()
    targetObject1.position.set(12, 4, 6)
    const targetObject2 = new THREE.Object3D()
    targetObject1.position.set(26, -0.4, -3.5)
    const capsule = useGLTF("./resumeIntro.glb", false, false, (result) => {
    });

    const actions = useAnimations(capsule.animations, scene)

    const getFOV = useCallback(() => {
        // const fov = (viewport.width / viewport.height) * viewport.width
        const superWide = viewport.width > (2 * viewport.height)
        const fov = superWide ? 30 : 90
        return fov
    }, [viewport])
   
    useEffect(() => {
        actions.actions["CameraAction.003"]!.timeScale = 0.0
        actions.actions["CameraAction.003"]!.play()
        if (capsule.cameras[0]) {
            camRef.current = capsule.cameras[0] as THREE.PerspectiveCamera
            camRef.current.fov = getFOV()
            camRef.current.aspect = viewport.width / viewport.height
            set({camera: camRef.current})
            camera.updateProjectionMatrix()

        }
    }, [capsule.cameras, actions.actions, set, camera, getFOV, viewport])

  


    useFrame((state, delta) => {
        const num = scroll.range(0, 5)
        actions.actions["CameraAction.003"]!.time = num * 60
});

    return (
        <Suspense fallback={<Loader />}>
            <ambientLight intensity={1} />
            <directionalLight
                color="white"
                target={capsule.scene}
                position={[20,30,-40]}
                intensity={3}
            />
             <directionalLight
                color="white"
                target={targetObject1}
                position={[-15.5,-5,-10]}
                castShadow
                intensity={1}
            />
            <directionalLight
                color="white"
                target={targetObject2}
                position={[1, 5, 6]}
                castShadow
                intensity={5}
            />
            <Scroll>
            <primitive object={capsule.scene}  />
            {/* <Plane position={[0, 0, 10]} rotation={[0, Math.PI , 0]} scale={3}>
                <Text scale={0.05} color="black" position={[0, 0, 0.1]}>HIRE ME</Text>
            </Plane> */}
            </Scroll>
        </Suspense>
    );
}

